html,
body,
#root {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  color: #666 !important;
  font-size: 14px;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  line-height: 1.42857;
}

.car-header {
  min-height: 0;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.car-header h2 {
  font-size: 20px;
  color: #ffffff;
  font-weight: normal;
  margin-bottom: 0;
}
.App {
  text-align: center;
}
.autoLoanTitle::before {
  content: url('images/carLoginIcon.png');
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.bank-logo-header {
  padding-top: 10px;
  padding-bottom: 30px;
}
.header-new{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.linkStyle {
  color: white;
  text-decoration: none;
}

/* Set veiwport height*/

.footer {
  background-color: #27a9e1;
  /* height: 50px;
    line-height: 50px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  color: #fff;
  font-size: 13px !important;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;


  /* margin-bottom: 0;
  margin-top: auto;

   position:fixed;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  font-size: 0.7em;*/
}

@media screen and (max-width: 991px) {
  .footer {
    padding: 15px 10px;
    line-height: 1.5;
    /* position: static;*/
  }
}
.global-menu-background {
  background-color: #27a9e1;
  border-color: #27a9e1;
}
/*Landing page styles */
.inner-wrapper {
  padding: 20px 15px 40px;
  margin-bottom: 10rem;
}

.info-section-block {
  border-radius: 0.75rem;
  text-align: left;
  padding: 15px 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
}
.smallBtn{
  font-size: smaller;
}
.subTitle1 {
  font-size: 28px;
  color: #7ac142;
  font-weight: normal;
  margin-top: 0 !important;
  margin-bottom: 0;
}

.info-section-block .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  background-color: #fff;
  background-image: none;
  max-width: 300px;
}
.btn.btn-primary {
  background-color: #3bb54a;
  border: 1px solid #37aa46;
  background: linear-gradient(
    to bottom,
    #96cb47 38%,
    #65bf49 55%,
    #3bb54a 100%
  );
  margin: auto;
  transition: 10ms;
  transition-timing-function: linear;
  padding: 6px 18px 4px;
}
.small-btn{
  font-size: smaller;
}
.btn.btn-primary:hover {
  background: #3bb54a !important;
  border: #3bb54a;
  border: 1px solid #37aa46;
  padding: 6px 18px 4px;
}

/*custom style for submit button*/
.submit-button {
  background-color: #27a9e1 !important;
  border-color: transparent !important;
  padding: 6px 18px 4px;
}

.submit-button:hover {
  background-color: #3db2e4 !important;
  border-color: transparent;
  padding: 6px 18px 4px;
}

.btn.btn-secondary {
  background-color: #a5afa6 !important;
  border: 1px solid #a5afa6 !important;
  background: linear-gradient(
    to bottom,
    #a2a39f 38%,
    #8b8b8a 55%,
    #8d918e 100%
  );
  transition: 10ms;
  transition-timing-function: linear;
  padding: 6px 18px 4px;
}

.btn.btn-secondary:hover {
  background: #4b4e4c;
  border: #4e574f;
  border: 1px solid #6e746f;
  padding: 6px 18px 4px;
}

.btn.btn-success {
  background-color: #7ac142 !important;
  border-color: #7ac142 !important;
  color: #ffffff !important;
}
.btn.btn-success:hover {
  background-color: #7ac142 !important;
  border-color: #7ac142 !important;
  color: #ffffff !important;
}

.trueIdButton {
  line-height: 1 !important;
  padding-bottom: 10px !important;
  border-radius: 3px !important;
}

/* Hide the increment/decreament default style of input type=number fields */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/*****************************/

/*Form styles landing page */

.invalid-feedback1 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

#__lpform_lastName_icon {
  display: none;
}
.bg-img-none {
  background-image: none !important;
  background: none;
}

/* labels in the form  */

.formLabel {
  text-align: left !important;
  margin-bottom: 5px !important;
  display: block;
  font-weight: bold;
}
#applicantInfo .formLabel {
  max-width: 300px;
}
.star-required {
  color: red;
}
.form{
  -moz-padding-start: calc(.75rem - 3px);
  /* -webkit-appearance: none; */
  /* appearance: none; */
  background-color: #fff;
  /* background-position: right .75rem center; */
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem 2.25rem .375rem .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
}
/* placeholder styles*/
::-webkit-input-placeholder {
  /* Edge */
  color: #ced4da;
  font-size: small;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #ced4da;
  font-size: small;
}

::placeholder {
  color: #ced4da;
  font-size: small;
}

/*OTP1 Radio button styles*/
/* .form-check {
  display: flex;
  padding-left: 0;
} */
#radioGroup .form-check-label {
  text-align: left;
  display: block;
  align-items: end;
  /* flex: .375; */
  padding-left: 30px;
  letter-spacing: 2px;
  font-weight: bold;
}
#radioGroup label:last-of-type {
  padding-left: 15px;
}

/* Error Message */
.errorMessage {
  margin: 0px 0px 10px;
  border: 1px solid;
  padding: 8px;
  border-radius: 3px;
  background-color: #dc3545;
  color: #ffffff;
  margin-bottom: 30px;
}

/*Resend otp style */
.resendOtp {
  font-size: small;
  text-align: left;
}
.pointer {
  cursor: pointer;
}

/* Start Loader styles */

.loader {
  height: 40vmin;
  pointer-events: none;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  /*margin: 10% 0 3% 35%;*/
}

@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: loader-spin infinite 2s linear;
  }
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* End Loader styles */
/*set spinner style in capture photo */
.spinner-element {
  width: 6rem !important;
  height: 6rem !important;
  color: #27a9e19e !important;
  border-width: 0.5em !important;
}

/*placeholder class */
.placeHolder1 {
  width: 275px;
  height: 125px;
  background-color: #0d6efd17;
  margin: 0 auto 0 auto;
}

/* Selfie image styles  */

.imgPreview img {
  /* max-width:100%;
  height:auto;*/
  width: 270px;
  height: 170px;
  border: 1px solid silver;
}
.react-html5-camera-photo > video {
  width: 320px !important;
}
/*Confirm Page */
#confirmImageLabel span.badge {
  padding: 8px 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 1px solid #3db2e4 !important;
  background-color: white !important;
  color: #1d9bd1;
  font-size: 0.95rem;
}
.rotate {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.info-icon {
  color: rgb(39, 169, 225);
  margin-top: -4px;
  font-size: 17px;
  font-weight: bold;
}
/*Selfie page */
#adjustAlign {
  text-align: center;
}
/*@media screen and (max-width: 900px) and (min-width: 600px) {
  #adjustAlign
  {margin-left: 2.2em;}
}*/

/* Result Page */
.selfie-image {
  border: 1px solid #adb5bd;
  box-shadow: inset 0 0 10px;
}

/*Processing screen styles */
#statusBarBadge span.badge {
  padding: 9px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.status-pipe {
  width: 45px;
  background-color: transparent;
  border-radius: 5px;
  margin-top: 15px;
  height: 0.3rem !important;
}
.status-bar-title {
  width: 70px;
}
.status-bar-font {
  font-size: 0.65em;
  font-weight: bold;
}

/* Google recaptcha resize styles   */
/*.g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}*/

@media screen and (max-height: 767px) {
  #rc-imageselect {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
@media screen and (min-width: 360px) and (max-width: 400px) {
  .g-recaptcha {
    transform: scale(0.87);
    -webkit-transform: scale(0.87);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  #applicantInfo .form-control,
  #applicantInfo .formLabel {
    width: 266px;
  }
}
@media screen and (max-width: 359px) {
  .g-recaptcha {
    transform: scale(0.57);
    -webkit-transform: scale(0.57);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.g-recaptcha * {
  width: 100%;
}

.alert {
  transition-timing-function: ease-out;

  /* A litttttle slower on the way in */
  transition: 0.25s;

  /* Move into place */
  transform: translateY(0);
  /* position: 'fixed';
  z-index: 1;
  top: '10px';
  right: '10px';
  display: 'inline'; */
}
.cellphone input {
  margin-right: 5px;
}
.cellphone label {
  position: relative;
  top: 1;
}

.suggestions{
list-style: none;
margin: 0;
padding: 0;
border-top: 1px solid #d4d4d4;
background-color: #f7f7f7;
border-radius: 0 0 10px 10px;
z-index: 3;
display: block;
position: relative;
}

.suggestions li {
  list-style: none;
  padding: 10px; 
  cursor: pointer;
}

.container-fluid{
  width: 100%;
    padding: auto;
    /* padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

.d-flex{
  display: flex!important;
}

.justify-content-center {
  justify-content: center!important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem!important;
}

/* @media (max-width: 576px)
.smart-form .container-fluid {
    padding-left: 0;
    padding-right: 0;
} */

.text-center{
  white-space: normal;
  text-align: center;
  /* box-sizing: border-box; */
}
.p-0 {
  padding: 0!important;
}
*, :after, :before {
  box-sizing: border-box;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.hidden {
  clip: rect(1px,1px,1px,1px);
  height: 1px;
  left: 50%;
  overflow: hidden;
  position: absolute!important;
  width: 1px;
}
.smart-form-radio-response-with-icon {
  width: 90%;
  height: 5.25rem;
}
/* input[type=radio]:checked+label {
  background: unset;
  background-color: #000;
  box-shadow: 0 0 0 1px #000;
  transition: border .2s ease-in-out,
  background-color .2s ease-in-out,
  box-shadow .2s ease-in-out;
  border: 1px solid #000;
} */
/* input[type=radio]:checked+label {
  background: #603aa1;
  color: #fff;
} */


input[type=radio]:checked+label .smart-form-icon-text {
  background: unset;
  border: none;
  color: #fff;
}
.smart-form-radio-response-with-icon {
  border-radius: 0.75rem;
  height: 8.8rem;
  margin: 0.5rem;
  width: 6rem;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
}

.responseText.smart-form-icon-text.sf-icon-text-double {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  margin: auto;
  width: 100%;
  font-weight: 500;
}
.smart-form-radio-response-with-icon .sf-icon-text-double {
  bottom: unset;
}

.smart-form-radio-response-with-icon {
  cursor: pointer;
  margin: 1rem;
  position: relative;
  text-align: center;
  transition: all .5s;
  width: 210px;
  background-color: white;
  z-index: 1;
}
.smart-form-radio-response-with-icon:hover {
  background: #fff;
  border-radius: 10px;
  /* color: #fff; */
  transform: scale(1.2);
  z-index: 2;
}

.bank-logo{
  display: flex;
flex-wrap: wrap;
  justify-content: space-between;
}

.phone-number{
  color: #000;
  align-self: center;
  display: flex;
  text-decoration: none;
  font-size: large;
}

.phone-number:hover{
  text-decoration: underline;
  color: #000;
}

.status-begin{
  /* text-decoration: 'none' !important; */
  color: #0d6efd;
  }

.status-complete{
  color: #198754;
}
.status-begin:hover{
  /* text-decoration: 'none' !important; */
  color: #0d6efd;
  }

.status-complete:hover{
  color: #198754;
}